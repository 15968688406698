<template>
    <div>

        <div class="card bd-0 mg-b-20" style="background: transparent;">
            <div class="card-header bg-primary tx-white">
                <ul class="nav nav-tabs nav-tabs-for-dark card-header-tabs">
                    <li v-for="t in tabs" :key="'tab-' + t.name" class="nav-item" :class="{'active': t.showing}">
                        <a class="nav-link text-white bd-0 pd-y-8 ft-left" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="selectTab(t.name)">{{t.name}}</a>
                        <a v-if="t.name !== 'Search'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-close" style="padding-left: 0; padding-right: 0;" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="closeTab(t.name)"><i class="fas fa-times"></i></a>
                        <a v-if="t.name !== 'Search'" class="nav-link text-white bd-0 pd-y-8 ft-right tab-reload" style="padding-left: 0; padding-right: 0; margin-left: -1rem;" :class="{'active pd-y-8': t.showing}" href="javascript:void(0);" @click="reloadClaim(t.name)"><i class="fa-solid fa-repeat"></i></a>
                    </li>
                </ul>
            </div><!-- card-header -->
            <div class="card-body" style="background: transparent;">
                <div v-show="selectedTab.name === 'Search'">
                    <div class="row">
                        <div class="col-3">
                            <div class="card shadow-base widget-11" style="cursor: pointer;" @click="toggleFilters">
                                <div class="card-header" style="border: none;">
                                    <div class="card-title">Filters <span v-if="selectedFilters > 0">({{selectedFilters}} selected)</span></div>
                                    <a href="javascript:void(0);"><i v-show="!filters.open" class="fa fa-angle-down"></i><i v-show="filters.open" class="fa fa-angle-up"></i></a>
                                </div><!-- card-header -->
                            </div>
                        </div>
                        <div class="col-9">
                            <pagination :showCount="true" :data="pagination" @search="searchClaims"></pagination>
                        </div>
                    </div>
                    <div class="row">
                        <div v-show="filters.open" class="col-3">
                            <!-- SEARCH BUTTON -->
                            <button @click="applyFilters" class="btn btn-primary mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p">APPLY FILTERS</button>

                            <!-- CLEAR FILTERS BUTTON -->
                            <button v-show="selectedFilters > 0" @click="clearFilters" class="btn btn-danger mg-t-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p">CLEAR FILTERS</button>

                            <!-- TEXT FILTERS -->
                            <div class="card shadow-base mg-t-20 widget-11">
                                <div class="card-header">
                                    <div class="card-title">Search</div>
                                </div><!-- card-header -->
                                <div class="card-body">
                                    <div class="form-group">
                                        <input v-on:keyup.enter="applyFilters" type="text" v-model="filters.options.text.registration" class="form-control form-control-dark tx-14" placeholder="Vehicle Registration">
                                    </div><!-- form-group -->
                                    <div class="form-group">
                                        <input v-on:keyup.enter="applyFilters" type="text" v-model="filters.options.text.reference" class="form-control form-control-dark tx-14" placeholder="Claim Reference">
                                    </div><!-- form-group -->
                                    <p class="tx-white">Heads Up! The status filter overrides the Status checkboxes below.</p>
                                    <div class="form-group">
                                        <select v-model="filters.options.text.status" class="form-control form-control-dark select2" multiple="multiple" v-select2>
                                            <option v-for="s in statuses" :value="s">{{s}}</option>
                                        </select>
                                    </div><!-- form-group -->
                                    <div v-show="filters.options.text.status === 'Cancelled'" class="form-group">
                                        <select id="cancellationReasonSelect" v-model="filters.options.text.cancellation_reason" data-tags="true" class="form-control select2" v-select2>
                                            <option :value="''" :selected="filters.options.text.cancellation_reason === ''">Select a Cancellation Reason</option>
                                            <option value="Invalid Claim (i.e. Fake plates)">Invalid Claim (i.e. Fake plates)</option>
                                            <option value="Customer (i.e Hotel Guest)">Customer (i.e Hotel Guest)</option>
                                            <option value="Requested by Site">Requested by Site</option>
                                            <option value="Unable to continue (i.e. Ignored all letters)">Unable to continue (i.e. Ignored all letters)</option>
                                            <option value="Possible Staff / Staff">Possible Staff / Staff</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <select v-model="filters.options.text.site" class="form-control select2" v-select2>
                                            <option :value="''" :selected="filters.options.text.site === ''">Select a Site</option>
                                            <option v-for="s in sites" :value="s.id" :key="'site-' + s.id">{{s.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <select v-model="filters.options.text.message" class="form-control select2" v-select2>
                                            <option :value="''" :selected="filters.options.text.message === ''">Select a Progress Message</option>
                                            <option v-for="m in messages" :value="m" :key="'message-' + m">{{m}}</option>
                                        </select>
                                    </div>
                                </div><!-- card-block -->
                            </div>

                            <!-- TYPE FILTERS -->
                            <div class="card shadow-base mg-t-20 widget-11">
                                <div class="card-header">
                                    <div class="card-title">Types</div>
                                </div><!-- card-header -->
                                <div class="card-body">
                                    <select v-model="filters.options.type" class="form-control form-control-dark select2" multiple="multiple" v-select2>
                                        <option v-if="$can('nmop-view')" :value="'nmop'">No Means of Payment</option>
                                        <option v-if="$can('driveoffs-view')" :value="'driveoff'">Drive Off</option>
                                        <option v-if="$can('cnaf-view')" :value="'cnaf'">Customer Not at Fault</option>
                                        <option v-if="$can('parking-view')" :value="'parking'">Parking</option>
                                        <option v-if="$can('parking-view')" :value="'evparking'">EV Parking</option>
                                    </select>
                                </div><!-- card-block -->
                            </div>

                            <!-- SUB-TYPE FILTERS -->
                            <div class="card shadow-base mg-t-20 widget-11">
                                <div class="card-header">
                                    <div class="card-title">Parking Sub-Type</div>
                                </div><!-- card-header -->
                                <div class="card-body">
                                    <select v-model="filters.options.parking_sub_type" class="form-control form-control-dark select2" v-select2>
                                        <option v-for="type in subTypes" :value="type.name">{{ type.name }}</option>
                                    </select>
                                </div><!-- card-block -->
                            </div>

                        </div>
                        <div :class="{'col-9': filters.open, 'col-12': !filters.open}" class="mg-t-20">
                            <table v-show="!searching" width="100%" class="table table-striped table-hover tx-white">
                                <thead class="thead-colored thead-primary">
                                <tr>
                                    <th v-show="!filters.open">#</th>
                                    <th>Type</th>
                                    <th>Plate</th>
                                    <th>Reference</th>
                                    <th v-show="!filters.open">Site</th>
                                    <th>Notes</th>
                                    <th>Progress Message</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Incident Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="claim in claims" style="cursor: pointer" :key="'claim-' + claim.id" @click="selectClaim(claim)">
                                    <td v-show="!filters.open">{{claim.id}}</td>
                                    <td>{{claim.type}} <span v-if="claim.escalated">(No Means of Payment)</span> <span v-if="claim.sub_type">({{ claim.sub_type }})</span></td>
                                    <td>{{claim.vehicle.plate}}</td>
                                    <td>
                                        {{claim.reference}}
                                        <span v-show="claim.presence.length > 0" class="text-info" v-tooltip="getClaimPresenceOverview(claim)">
                                            ({{ claim.presence.length }} {{ claim.presence.length > 1 ? 'viewers' : 'viewer' }})
                                        </span>
                                    </td>
                                    <td v-show="!filters.open">{{claim.site.reference}} - {{claim.site.name}}</td>
                                    <td><span v-if="claim.note_count > 0" class="badge badge-primary">{{claim.note_count}}</span></td>
                                    <td v-if="(claim.type === 'parking' || claim.type === 'evparking') && claim.status === 'KADOE Error'">
                                        {{claim.post_failure_reason}}
                                    </td>
                                    <td v-else>{{claim.progress_message}}</td>
                                    <td :class="{'text-success': claim.status === 'Debt Collection Review' && claim.dc_verified == 1}">{{claim.status}}</td>
                                    <td>{{claim.date_received}}</td>
                                    <td>
                                        {{ claim.incident_timestamp || '-' }}
                                        <span v-if="claim.incident_timestamp">({{ claim.days_since_incident.toFixed(0) }} days ago)</span>
                                    </td>
                                </tr>
                                <tr v-if="claims.length < 1">
                                    <td colspan="9" class="tx-center">No claims were found that match your search criteria.</td>
                                </tr>
                                </tbody>
                            </table>
                            <loader :show="searching"></loader>
                        </div>
                    </div>
                </div>
                <claim v-for="tab in claimTabs" :ref="'claim-' + tab.id" v-show="tab.showing" :key="tab.id" :claim_id="parseInt(tab.id)" :presence="presence.users" @open-claim="selectClaim" @triggerNext="triggerNext"></claim>
            </div>
        </div>

    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import Loader from '@/components/Loader.vue';
import Claim from '@/views/claims/Claim.vue';
import authHeader from '@/services/auth-header.js';
import {DateTime} from 'luxon';
import axios from 'axios';
import Socket from '@/services/socket.js';

export default {
    name: 'Claims',
    components: {
        Pagination,
        Loader,
        Claim
    },
    props: {
        reference: {
            type: String,
            required: false
        }
    },
    data(){
        return {
            filters: {
                open: true,
                options: {
                    type: ["driveoff", "nmop", "parking", "cnaf", "evparking"],
                    status: {
                        actionable: true,
                        waiting: false,
                        nonactionable: false,
                        excludeLocked: false
                    },
                    text: {
                        registration: "",
                        reference: "",
                        site: "",
                        status: [],
                        message: "",
                        cancellation_reason: ""
                    },
                    parking_sub_type: null
                },
                selectedOptions: {
                    type: [],
                    status: {
                        actionable: true,
                        waiting: false,
                        nonactionable: false,
                        excludeLocked: false
                    },
                    text: {
                        registration: "",
                        reference: "",
                        site: "",
                        status: "",
                        message: "",
                        cancellation_reason: ""
                    },
                    parking_sub_type: null
                },
            },
            messages: [
                "Cloned Plate",
                "Waiting for Receipt",
                "Waiting for Clearer Image",
                "Waiting for Evidence",
                "Tampered Plate",
                "System Issue",
                "Suspected Staff",
                "Suspected Guest",
                "Waiting for DVLA Response (KADOE)",
                "Payment Plan",
                "At POPLA"
            ],
            subTypes: [],
            pagination: {
                currPage: 1,
                totalResults: 0,
                from: 0,
                to: 0,
                perPage: 25,
                lastPage: 1,
            },
            counts: {
                driveoff: 0,
                nmop: 0,
                parking: 0,
                actionable: 0,
                waiting: 0,
                nonactionable: 0
            },
            sites: [],
            claims: [],
            tabs: [
                {
                    name: "Search",
                    claim: false,
                    id: -1,
                    showing: true
                }
            ],
            searching: false,
            statuses: [],
            socket: null,
            presence: {
                users: [],
                interval: null
            },
            continuousMode: false
        }
    },
    created(){
        this.socket = new Socket(this.$store.state.auth.user.ws_url);
    },  
    unmounted(){
        this.socket.off('open-claim', (message) => {
            this.presence.users.push(message.content);
        });
        this.socket.off('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.content.user.id
                );
            });
        });
        this.socket.off('new-note', (message) => {
            console.log(`New note: ${message.reference}`);
            //message.reference
            let findTab = this.tabs.filter(tab => {
                return tab.name === message.reference;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.newNote(message);
        });
        this.socket.off('new-evidence', (message) => {
            //message.reference
            let findTab = this.tabs.filter(tab => {
                return tab.name === message.reference;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.newEvidence(message);
        });
        this.socket.off('new-cancellation', (message) => {
            //message.reference
            let findTab = this.tabs.filter(tab => {
                return tab.name === message.reference;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.newCancellation(message);
        });
        clearInterval(this.presence.interval);
    },  
    async beforeRouteLeave(to, from, next){
        let claimTabs = this.tabs.filter(tab => {
            return tab.claim == true;
        });

        if(claimTabs.length > 0){
            console.log(`Found ${claimTabs.length} claim tabs`);
            for await (let tab of claimTabs){
                console.log(`Checking tab ${tab.id}`);
                let component = this.$refs[`claim-${tab.id}`][0];
                let canClose = await component.triggerClose();
                console.log(`Tab ${tab.id} can close: ${canClose}`);
                if(!canClose){
                    next(false);
                    return;
                }else{
                    if(tab.showing){
                        this.tabs.forEach(t => {
                            t.showing = t.name === 'Search';
                        });
                    }

                    this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});

                    this.tabs = this.tabs.filter(t => {
                        return t.id !== tab.id;
                    });
                }
            }
        }
        if(!this.$store.state.showClientSelector){
            this.$store.commit('toggleClientSelector');
        }
        next();
    },  
    mounted(){
        this.socket.on('open-claim', (message) => {
            message._ts = Date.now();
            if(this.presence.users.filter(u => {
                return u.reference == message.reference &&
                    u.user.id == message.user.id
            }).length > 0){
                this.presence.users.filter(u => {
                    return u.reference == message.reference &&
                        u.user.id == message.user.id
                })[0]._ts = Date.now();
                return;
            }
            this.presence.users.push(message);
            this.claims.forEach((claim) => {
                if(claim.reference == message.reference){
                    if(claim.presence.filter((u) => {
                        return u.reference == message.reference &&
                            u.user.id == message.user.id
                    }).length < 1){
                        claim.presence.push(message);
                    }
                }
            });
        });
        this.socket.on('close-claim', (message) => {
            this.presence.users = this.presence.users.filter(u => {
                return !(
                    u.reference == message.reference &&
                    u.user.id == message.user.id
                );
            });
            this.claims.forEach((claim) => {
                if(claim.reference == message.reference){
                    claim.presence = claim.presence.filter((u) => {
                        return !(
                            u.reference == message.reference &&
                            u.user.id == message.user.id
                        );
                    });
                }
            });
        });
        this.socket.on('new-note', (message) => {
            console.log(`New note: ${message.reference}`);
            //message.reference
            let findTab = this.tabs.filter(tab => {
                return tab.name === message.reference;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.newNote(message);
        });
        this.socket.on('new-evidence', (message) => {
            console.log(`New evidence`);
            //message.reference
            let findTab = this.tabs.filter(tab => {
                return tab.name === message.reference;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.newEvidence(message);
        });
        this.socket.on('new-cancellation', (message) => {
            console.log(`New cancellation`);
            //message.reference
            let findTab = this.tabs.filter(tab => {
                return tab.name === message.reference;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.newCancellation(message);
        });
        this.presence.interval = setInterval(() => {
            this.tabs.forEach((tab) => {
                if(tab.name !== 'Search'){
                    this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                        "reference": tab.name,
                        "user": {
                            "id": this.$store.state.auth.user.id,
                            "email": this.$store.state.auth.user.email
                        }
                    }});
                }
            });

            this.presence.users = this.presence.users.filter((u) => {
                return Date.now() - u._ts < 15000;
            });
        }, 5000);

        axios.get(`https://api.varsanpr.com/api/clients/${this.$store.state.auth.user.selectedClient}/sites`, {
            headers: authHeader()
        })
        .then(response => {
            this.sites = response.data;
            $(".select2").select2();
            $("#cancellationReasonSelect").select2({tags: true});
            this.searchClaims();
        })
        .catch(error => {
            this.$error("Unable to update site list", error);
        });
        let reference = this.reference;
        console.log(this.$route);
        console.log(this.$route.fullPath.split('?'));
        let params = this.$route.fullPath.split('?');
        if(params.length > 1){
            let query = params[1].split('&');
            query.forEach(q => {
                let parts = q.split('=');
                if(parts[0] === 'reference'){
                    reference = parts[1];
                }
            });
        }
        if(this.$route.query.reference){
            if(reference === null || reference === undefined){
                reference = this.$route.query.reference;
            }
            console.log(`Reference from query: ${reference}`);
            this.$route.query.reference = null;
        }
        if(reference !== null && reference !== undefined){
            if(reference.length > 7){
                this.searching = true;
                axios.get(`https://api.varsanpr.com/api/claims?client_id=${this.$store.state.auth.user.selectedClient}&page=1&reference=${reference}&type=driveoff,nmop,parking,evparking,cnaf`, {
                    headers: authHeader()
                })
                .then(response => {
                    if(response.data?.claims[0] !== undefined){
                        let claim = response.data.claims[0];
                        this.selectClaim(claim);
                    }
                })
                .catch(error => {
                    this.$error("Unable to automatically redirect to " + this.reference, error);
                    this.searching = false;
                })
            }
        }
        axios.get(`https://api.varsanpr.com/api/claims/statuses?client_id=${this.$store.state.auth.user.selectedClient}`, {
            headers: authHeader()
        })
        .then(response => {
            this.statuses = response.data.statuses;
        })
        .catch(error => {
            this.$error("Failed to load claim statuses", error);
        })
    },
    methods: {
        getClaimPresenceOverview(claim){
            return claim.presence.map((u) => {
                return u.user.email;
            }).join(", ");
        },
        selectTab(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            this.tabs.forEach(tab => {
                tab.showing = false;
            });

            findTab.showing = true;

        },
        reloadClaim(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            component.loadClaim(true);
        },
        async closeTab(t){
            let findTab = this.tabs.filter(tab => {
                return tab.name === t;
            })[0];

            if(!findTab) return;

            let component = this.$refs[`claim-${findTab.id}`][0];
            console.log(component);

            let canClose = await component.triggerClose();

            if(!canClose) return;

            if(findTab.showing){
                this.tabs.forEach(tab => {
                    tab.showing = tab.name === 'Search';
                });
            }

            this.socket.sendToGroup('claims', {"event": "close-claim", "content": {
                "reference": findTab.name,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});

            this.tabs = this.tabs.filter(tab => {
                return tab.id !== findTab.id;
            });

            this.searchClaims(this.pagination.currPage);

            if(!this.$store.state.showClientSelector && this.tabs.length < 2){
                this.$store.commit('toggleClientSelector');
            }
        },
        triggerNext(claim){
            if(!this.continuousMode || this.$store.state.auth.user.id != 3) return;
            let findTab = this.tabs.filter(tab => {
                return tab.name === claim.reference;
            })[0];

            if(!findTab) return;

            // Locate the next claim in the list

            // Locate location of current claim using the reference
            let currentClaim = this.claims.filter(c => {
                return c.reference === claim.reference;
            })[0];
            let nextClaim = this.claims[this.claims.indexOf(currentClaim) + 1];

            if(nextClaim){
                this.selectClaim(nextClaim);
                this.closeTab(claim.reference);
            }else{
                this.closeTab(claim.reference);
            }
        },
        selectClaim(claim){
            // $router.push(`/claims/management/${claim.id}`);
            let existingCheck = this.tabs.filter(t => {
                return t.id == claim.id;
            })[0];
            if(existingCheck){
                this.tabs.forEach(t => {
                    t.showing = t.id === claim.id;
                });
                return;
            }
            this.tabs.forEach(t => {
                t.showing = false;
            });
            this.tabs.push({
                name: claim.reference,
                id: claim.id,
                claim: true,
                showing: true
            });
            this.socket.sendToGroup('claims', {"event": "open-claim", "content": {
                "reference": claim.reference,
                "user": {
                    "id": this.$store.state.auth.user.id,
                    "email": this.$store.state.auth.user.email
                }
            }});

            if(this.$store.state.showClientSelector && this.tabs.length > 1){
                this.$store.commit('toggleClientSelector');
            }
        },
        searchClaims(page = 1){
            let url = `https://api.varsanpr.com/api/claims`;
            let query = [{name: 'client_id', value: this.$store.state.auth.user.selectedClient}];
            query.push({name: 'page', value: page});
            if(this.qc(this.filters.options.text.site)){
                query.push({name: "site_id", value: this.filters.options.text.site});
            }
            if(this.qc(this.filters.options.text.reference)){
                query.push({name: "reference", value: this.filters.options.text.reference});
            }
            if(this.qc(this.filters.options.text.status)){
                query.push({name: "status", value: this.filters.options.text.status});
            }
            if(this.qc(this.filters.options.text.message)){
                query.push({name: "message", value: this.filters.options.text.message});
            }
            if(this.qc(this.filters.options.text.registration)){
                query.push({name: "registration", value: this.filters.options.text.registration.replace(/\s/g, '')});
            }
            if(this.filters.options.type.length > 0){
                query.push({name: "type", value: this.filters.options.type.join(",")});
            }
            if(this.qc(this.filters.options.status.excludeLocked)){
                query.push({name: "locked", value: this.filters.options.status.excludeLocked});
            }
            if(this.qc(this.filters.options.parking_sub_type) && this.filters.options.type.includes('parking')){
                query.push({name: "subtype", value: this.filters.options.parking_sub_type});
            }

            if(query.length > 0){
                let queryString = "?";
                query.forEach((q) => {
                    queryString = `${queryString}${q.name}=${q.value}&`;
                });
                queryString = queryString.slice(0, -1);
                url = `${url}${queryString}`;
            }

            this.searching = true;

            axios.get(url, {
                headers: authHeader()
            })
                .then(response => {
                    response.data.claims.forEach((claim) => {
                        claim.date_received = DateTime.fromSeconds(parseInt(claim.date_received)).toFormat("dd/MM/yyyy")
                        claim.escalated = claim.type === 'escalated';
                        switch(claim.type){
                            case 'driveoff':
                            case 'escalated':
                            case 'cnafe':
                                claim.type = 'Drive Off';
                                break;
                            case 'nmop':
                                claim.type = 'No Means of Payment';
                                break;
                            case 'parking':
                                claim.type = 'Parking';
                                break;
                            case 'evparking':
                                claim.type = 'EV Parking';
                                break;
                            case 'cnaf':
                                claim.type = 'Customer Not at Fault';
                                break;
                        }
                        claim.days_since_incident = null;
                        if(claim.incident_timestamp){
                            claim.days_since_incident = Math.abs(DateTime.fromSeconds(parseInt(claim.incident_timestamp)).diffNow('days').toObject().days.toFixed(2));
                            claim.incident_timestamp = DateTime.fromSeconds(parseInt(claim.incident_timestamp)).toFormat("dd/MM/yyyy");
                        }
                        claim.presence = this.presence.users.filter(u => {
                            return u.reference == claim.reference;
                        }) || [];
                    })
                    this.claims = response.data.claims;
                    this.pagination.currPage = parseInt(response.data.pagination.page);
                    this.pagination.totalResults = response.data.pagination.total;
                    this.pagination.from = 1 + (response.data.pagination.page -1) * this.pagination.perPage;
                    this.pagination.to = 1 + response.data.pagination.page * this.pagination.perPage;
                    if(this.pagination.to > this.pagination.totalResults){
                        this.pagination.to = this.pagination.totalResults;
                    }
                    this.pagination.lastPage = response.data.pagination.lastPage;
                    // this.counts = response.data.counts;
                    $(".select2").select2();
                    $("#cancellationReasonSelect").select2({tags: true});
                })
                .catch(error => {
                    this.$error("Unable to load claims", error);
                })
            .finally(() => {
                this.searching = false;
            });
        },
        qc(val){
            if(val === null) return false;
            if(val === undefined) return false;

            if(typeof val === 'string'){
                if(val.length < 1) return false;
            }

            return true;
        },
        toggleFilters(){
            this.filters.open = !this.filters.open;
        },
        applyFilters(){
            if(!this.optionsMatch){
                this.filters.selectedOptions = JSON.parse(JSON.stringify(this.filters.options));
            }
            this.searchClaims();
        },
        clearFilters(){
            this.filters.options = {
                type: {
                    driveoff: false,
                    nmop: false,
                    parking: false,
                    evparking: false,
                    cnaf: false
                },
                status: {
                    actionable: false,
                    waiting: false,
                    nonactionable: false
                },
                text: {
                    registration: "",
                    reference: "",
                    site: ""
                }
            };
            this.filters.selectedOptions = JSON.parse(JSON.stringify(this.filters.options));
            $(".select2").select2();
            $("#cancellationReasonSelect").select2({tags: true});
        },
        getSubTypes(){
            axios.get(`https://api.varsanpr.com/api/parking/types`, {
                headers: authHeader()
            })
            .then(response => {
                this.subTypes = response.data;
            })
            .catch(error => {
                this.$error("Failed to load sub types", error);
            });
        }
    },
    computed: {
        selectedFilters: function(){
            let count = 0;
            if(this.filters.selectedOptions.type.driveoff) count++;
            if(this.filters.selectedOptions.type.nmop) count++;
            if(this.filters.selectedOptions.type.parking) count++;
            if(this.filters.selectedOptions.type.evparking) count++;
            if(this.filters.selectedOptions.type.cnaf) count++;
            if(this.filters.selectedOptions.status.actionable) count++;
            if(this.filters.selectedOptions.status.waiting) count++;
            if(this.filters.selectedOptions.status.nonactionable) count++;
            if(this.filters.selectedOptions.text.registration){
                if(this.filters.selectedOptions.text.registration.length > 0) count++;
            }
            if(this.filters.selectedOptions.text.reference){
                if(this.filters.selectedOptions.text.reference.length > 0) count++;
            }
            if(this.filters.selectedOptions.text.status){
                if(this.filters.selectedOptions.text.status.length > 0) count++;
            }
            if(this.filters.selectedOptions.text.site){
                if(this.filters.selectedOptions.text.site.toString().length > 0) count++;
            }
            return count;
        },
        optionsMatch: function (){
            return JSON.stringify(this.filters.options) === JSON.stringify(this.filters.selectedOptions);
        },
        selectedTab: function(){
            return this.tabs.filter(t => {
                return t.showing;
            })[0];
        },
        claimTabs: function(){
            return this.tabs.filter(tab => {
                return tab.claim == true;
            });
        }
    }
}
</script>

<style scoped>
.card-header-tabs.nav-tabs-for-dark .nav-link.active {
    border-bottom: 2px solid white;
    background-color: transparent;
}

.card-header-tabs.nav-tabs-for-dark .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: bold;
}

.tab-close:hover {
    color: red !important;
}

.tab-reload:hover {
    color: lightgreen !important;
}

.presence {
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 100px;
    width: 250px;
    padding: 10px;
    background-color: black;
    opacity: .8;
    color: white;
}

.is-viewed {
    background-color: #b91d287d !important;
}

.nav-item.active {
    border-bottom: 2px solid white;
}

.nav-item.active a {
    border-bottom: none!important;
}
</style>
